import { defineStore } from 'pinia'
import { useSitecoreGraphQueryStore } from '~/stores/sitecoreGraphQuery'
import { camelCase, startCase } from 'lodash-es'
import useHelpers from '~/composables/useHelpers'

import Payload = models.server.api.graphQuery.homepage.Root
import HomepageData = models.server.api.graphQuery.homepage.Data

interface State {
  data: HomepageData
}

export const useHomepageStore = defineStore('homepage', {
  state: (): State => ({
    data: {}
  }),

  actions: {
    async fetch() {
      if (this.data && Object.keys(this.data).length) return

      const sitecoreGraphQuery = useSitecoreGraphQueryStore()
      const { data } = await sitecoreGraphQuery.fetch<Payload>(
        'B8437627-B005-41CB-A431-56104C0CCEA2'
      )

      this.data = data || {}
    }
  },

  getters: {
    banner: (state) => {
      const { getImgSrc, substrBeforeLast } = useHelpers()
      const banner = state.data?.banner

      return banner
        ? {
            title: banner.title?.value,
            subtitle: banner.subtitle?.value,
            subtitleColour: banner.subtitleColour?.value,
            heroImage: {
              ...banner.heroImage,
              src: substrBeforeLast(getImgSrc(banner.heroImage?.src), '-')
            }
          }
        : undefined
    },
    partnerPrograms: (state) => {
      const partnerPrograms = state.data?.partnerLogosSection
      return partnerPrograms
        ? partnerPrograms.partnerLogos?.targetItems?.map((partner) => ({
            name: partner.title?.value,
            img: {
              src: partner.image?.src,
              alt: partner.image?.alt
            },
            url: partner.url?.url,
            external: partner.url?.linkType === 'external'
          }))
        : undefined
    },
    whyTafeNsw: (state) => {
      const whytafensw = state.data?.whytafensw
      return whytafensw
        ? {
            title: whytafensw.title?.value || '',
            description: whytafensw?.description?.value || '',
            terms: whytafensw.terms?.value || '',
            tiles:
              whytafensw.tiles?.targetItems?.map((item) => ({
                text: item.title?.value || '',
                icon: item.Icon?.value || '',
                color: `brand-${item.color?.value || 'blue'}-100` || ''
              })) || []
          }
        : undefined
    },
    promotions: (state) => {
      const promotions = state.data?.promotions
      return promotions
        ? {
            promotionsTitle: promotions.title?.value,
            promotionsSubtitle: promotions.subtitle?.value,
            promotionsSubtitleColour: promotions.subtitleColour?.value,
            promos: promotions.promos?.targetItems
          }
        : undefined
    },
    popularCourseAreas: (state) => {
      const popularCourseAreas = state.data?.popularCourseAreas
      return popularCourseAreas
        ? {
            title: popularCourseAreas.title?.value,
            buttonText: popularCourseAreas.button?.text
          }
        : undefined
    },
    popularCourseAreaList: (state) => {
      const popularCourseAreas = state.data?.popularCourseAreas
      return popularCourseAreas
        ? popularCourseAreas.courseAreas?.targetItems?.map((x) => {
            return {
              title: x.title?.value,
              url: `/course-areas/${x.url?.value}`,
              ...(x.icon?.value && {
                icon: startCase(camelCase(x.icon?.value)).replace(/ /g, '')
              })
            }
          })
        : undefined
    }
  }
})
